/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useRef, useContext, useEffect, useState,
} from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useSelector, useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import Link from 'next/link';
import {
  PlusOutlined, UserOutlined,
} from '@ant-design/icons';
import _ from 'lodash';
import axios from 'axios';
import LoadingModal from '../dialog/LoadingModal';
import AlertModal from '../dialog/AlertModal';
import { Verify } from '../../api/auth';
import { SettingActions } from '../reducers/settingReducer';
import { getGameCred } from '../../api/getGameCred';
import { getBalance } from '../../api/getBalance';
import ContactUsModal from '../dialog/contactUsModal';
import Footer from './Footer';
import {
  menuIconFAQ,
  menuIconFishing,
  menuIconHistory,
  menuIconRoulette,
  VolleyballIcon,
  SoccerIcon,
  TennisIcon,
  EsportIcon,
  menuSlots,
  menuTableGames,
  menuCardGames,
  menuInstantGames,
  menuDownloadApp,
  ukFlagsvg,
  menuIconPromotion,
  menuIconReferral,
  menuIconSlots,
  NavmenuSlot,
  profileLanguageIcon,
  NavmenuTableGames,
  NavmenuInstantGames,
  helpDeskPhoneIcon,
  menuIconSport,
  menuIconVIP,
  menuHotFlag,
  menuNewFlag,
  menuIconTermsAndConditon,
  MenuIconhistoryleft,
  MenuIconpromotiongreenleft,
  MenuIconreferralleft,
  MenuIcontermleft,
  MenuIconvipleft,
  MenuIconfaqleft,
  MenuIconpromotion,
  MenuIconalllive,
  MenuIconfavorities,
  MenuIcontopparlays,
  BasketballIcon,
  NavmenuLivecasino,
  CricketIcon,
  KabaddiIcon,
  // VolleyballIcon,
  GetBonus,
  MenuChevronLeft,
  // languageIcon,
  // SideMenuBack,
  // SideMenuLanguageDown,
  logoIcon,
  Hamburgersvg,
  DownloadApk,
  MenuIconIPL,
  MenuIconIPLColored,
  NavmenuCardGames,
} from '../../images';
import RegisterModal from '../dialog/RegisterModal';
import MobileMenuModal from '../dialog/MobileMenuModal';
// import { Logout } from '../../api/logout';
import { ENDPOINT } from '../../api/constants';
import LoginPage from '../login/LoginPage';
import NavbarBottom from './NavbarBottom';
import MenuSection from './MenuSection';
import LoginModal from '../dialog/LoginModal';
import { routes } from '../../route';
import LiveChat from './LiveChat';
import SideMenu from './SideMenu';
import { getBrand } from '../../api/getBrand';
import { countiesCode } from '../constant/countriesCode';
import { LayoutContext } from '../contexts/LayoutContextContainer';
import { depositCurrency } from '../../api/currency';

const LIVECHATURL = 'https://www.ymsza2.com/chat/text/chat_159tNY.html';

function Layout(props) {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const router = useRouter();
  const { referrer } = router.query;
  const isLoading = useSelector((state) => state.setting.loading);
  const isAlert = useSelector((state) => state.setting.alert);
  const [timer, setTimer] = useState(null);
  const [logout, setLogout] = useState(false);
  const [contactShow, setContactShow] = useState(false);
  const [isRelogin, setIsRelogin] = useState(false);
  const [showDepositModal, setShowDepositModal] = useState(false);
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  const [menuSelected, setMenuSelected] = useState(null);
  const ref = useRef(null);
  const firstLoad = useRef(true);
  const loginShow = useSelector((state) => state.setting.loginShow);
  const { balance } = useSelector((state) => state.setting);
  const isMobile = useSelector((state) => state.setting.isMobile);
  const isLogin = Cookies.get('accessToken');
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showUserProfileModal, setShowUserProfileModal] = useState(false);
  const [showCustomLiveChatModal, setShowCustomLiveChatModal] = useState(false);
  const [validBanners, setValidBanners] = useState([]);
  const [height, setHeight] = useState(0);
  const [bonusInfo, setBonusInfo] = useState({});
  const isId = Cookies.get('userId');
  const [hideSidebar, setHideSidebar] = useState(false);
  const [sportMatchesMobile, setSportMatchesMobile] = useState([]);
  const { setToPath } = useContext(LayoutContext);
  const windowSize = useSelector((state) => state.setting.windowSize);
  const [showLiveChat, setShowLiveChat] = useState(true);

  const elementWindow = typeof window !== 'undefined'
    && (document.getElementById('page-container') || null);
  let lastScrollY = elementWindow?.scrollTop || 0;

  const accessToSport = async (hasPath, demo) => {
    const brandInfo = await getBrand(process.env.SPORT_ID);
    const brandId = brandInfo?.data?._id;
    const gameId = brandInfo?.data?.games?._id;
    if (demo) {
      if (_.includes(hasPath, '/calendar/upcoming')) {
        dispatch(SettingActions.setSelected(1));
      } else if (_.includes(hasPath, '/home/live')) {
        dispatch(SettingActions.setSelected(0));
      }
      router.push(`/game_website?brandId=${'658a2e8decc17606d6055c36'}${hasPath ? `&toPath=${hasPath}` : ''}&demo=${demo}`);
    } else {
      const gameInfo = await getGameCred({ brandId: process.env.SPORT_ID });

      if (gameInfo?.error) {
        if (!gameInfo?.error.includes('jwt')) {
          dispatch(
            SettingActions.setAlert([
              {
                msg: gameInfo?.error,
              },
            ]),
          );
        }
        return false;
      }

      const gameProfileId = gameInfo?.data?.data?.[0]._id;
      if (_.includes(hasPath, '/calendar/upcoming')) {
        dispatch(SettingActions.setSelected(1));
      } else if (_.includes(hasPath, '/home/live')) {
        dispatch(SettingActions.setSelected(0));
      }
      router.push(`/game_website?brandId=${brandId}&gameProfileId=${gameProfileId}&gameId=${gameId}${hasPath ? `&toPath=${hasPath}` : ''}&demo=${demo}`);
    }
  };

  const accessTo9Wicket = async () => {
    dispatch(SettingActions.setLoading(true));
    if (!Cookies.get('accessToken')) {
      return dispatch(
        SettingActions.setAlert([
          {
            msg: 'Please login',
          },
        ]),
      );
    }

    const brand9WicketId = '6656fbc78afd0a4d83d43dae';
    const brandInfo = await getBrand(brand9WicketId);
    const brandId = brandInfo?.data?._id;
    const gameId = brandInfo?.data?.games?._id;
    const gameInfo = await getGameCred({ brandId: brand9WicketId });

    const gameProfileId = gameInfo?.data?.data?.[0]._id;
    router.push(`/game_website?brandId=${brandId}&gameProfileId=${gameProfileId}&gameId=${gameId}`);
  };

  const menuList2 = [
    {
      key: 0,
      label: '9wicket',
      type: '',
      icon: MenuIconalllive,
      action: () => {
        accessTo9Wicket();
      },
    },
    // {
    //   key: 0,
    //   label: 'All Live',
    //   type: '',
    //   icon: MenuIconalllive,
    //   action: () => {
    //     if (Cookies.get('accessToken')) {
    //       accessToSport('/home/live');
    //     } else {
    //       setToPath('/home/live');
    //       accessToSport('/home/live', true);
    //       // router.push('/game_website?gameAccess=https://sportsbet.esportings.com/home/live?siteId=812');
    //     }
    //   },
    // },
    // {
    //   key: 1,
    //   label: 'Favorities',
    //   type: '',
    //   icon: MenuIconfavorities,
    //   action: () => {
    //     if (Cookies.get('accessToken')) {
    //       accessToSport('/favourite/matches');
    //     } else {
    //       setToPath('/favourite/matches');
    //       accessToSport('/favourite/matches', true);
    //     }
    //   },
    // },
    // {
    //   key: 2,
    //   label: 'Top Parlays',
    //   type: '',
    //   icon: MenuIcontopparlays,
    //   action: () => {
    //     if (Cookies.get('accessToken')) {
    //       accessToSport('/home/highlights');
    //     } else {
    //       setToPath('/home/highlights');
    //       accessToSport('/home/highlights', true);
    //     }
    //   },
    // },
    // {
    //   key: 3,
    //   label: 'Bonuses',
    //   type: '',
    //   icon: MenuIconpromotion,
    //   action: () => {
    //     if (Cookies.get('accessToken')) {
    //       accessToSport('/home/promotions');
    //     } else {
    //       setToPath('/home/promotions');
    //       accessToSport('/home/promotions', true);
    //     }
    //   },
    // },
    // {
    //   key: 4,
    //   label: 'Cricket',
    //   type: '',
    //   icon: CricketIcon,
    //   action: () => {
    //     if (Cookies.get('accessToken')) {
    //       accessToSport('/sports/cricket');
    //     } else {
    //       setToPath('/sports/cricket');
    //       accessToSport('/sports/cricket', true);
    //     }
    //   },
    // },
    // {
    //   key: 5,
    //   label: 'Kabbadi',
    //   type: '',
    //   icon: KabaddiIcon,
    //   action: () => {
    //     if (Cookies.get('accessToken')) {
    //       accessToSport('/sports/kabaddi');
    //     } else {
    //       accessToSport('/sports/kabaddi', true);
    //     }
    //   },
    // },
    // {
    //   key: 6,
    //   label: 'Football',
    //   type: '',
    //   icon: SoccerIcon,
    //   action: () => {
    //     if (Cookies.get('accessToken')) {
    //       accessToSport('/sports/soccer');
    //     } else {
    //       setToPath('/sports/soccer');
    //       accessToSport('/sports/soccer', true);
    //     }
    //   },
    // },
    // {
    //   key: 7,
    //   label: 'Basketball',
    //   type: '',
    //   icon: BasketballIcon,
    //   action: () => {
    //     if (Cookies.get('accessToken')) {
    //       accessToSport('/sports/basketball');
    //     } else {
    //       setToPath('/sports/basketball');
    //       accessToSport('/sports/basketball', true);
    //     }
    //   },
    // },
  ];

  const menulist4 = [
    {
      key: 12,
      label: 'live casino',
      type: '',
      icon: menuIconRoulette,
      action: () => {
        dispatch(SettingActions.setLoading(true));
        router.push('/live-casino');
        setShow(false);
      },
    },
    {
      key: 13,
      label: 'Slots',
      type: '',
      icon: menuSlots,
      action: () => {
        dispatch(SettingActions.setLoading(true));
        router.push('/slots');
        setShow(false);
      },
    },
    {
      key: 14,
      label: 'Table Games',
      type: '',
      icon: menuTableGames,
      action: () => {
        dispatch(SettingActions.setLoading(true));
        router.push('/table-games');
        setShow(false);
      },
    },
    {
      key: 15,
      label: 'Card Games',
      type: '',
      icon: menuCardGames,
      action: () => {
        dispatch(SettingActions.setLoading(true));
        router.push('/card-games');
        setShow(false);
      },
    },
    {
      key: 16,
      label: 'Instant Games',
      type: '',
      icon: menuInstantGames,
      action: () => {
        dispatch(SettingActions.setLoading(true));
        router.push('/instant-games');
        setShow(false);
      },
    },
  ];

  const menulist5 = [
    {
      key: 17,
      labelLanguage: 'Language',
      text: 'English',
      type: '',
      icon: ukFlagsvg,
      action: () => {
        dispatch(SettingActions.setLoading(true));
        router.push('');
      },
    },
  ];

  const menulist6 = [
    {
      key: 18,
      label: 'Download APP',
      type: '',
      icon: menuDownloadApp,
      action: () => {
        dispatch(SettingActions.setLoading(true));
        router.push('https://betleon.s3.ap-southeast-1.amazonaws.com/apk/BLEON77.apk');
      },
    },
  ];
  const menuList3 = [
    // {
    //   key: 14,
    //   label: 'IPL Crazy Prize Pool',
    //   type: '',
    //   icon: MenuIconIPLColored,
    //   action: () => {
    //     dispatch(SettingActions.setLoading(true));
    //     router.push('/ipl');
    //   },
    //   flagIcon: menuNewFlag,
    // },
    {
      key: 7,
      label: 'promotions',
      type: '',
      icon: MenuIconpromotiongreenleft,
      action: () => {
        dispatch(SettingActions.setLoading(true));
        router.push('/promotions');
      },
      flagIcon: menuNewFlag,
    },
    {
      key: 99,
      label: 'history',
      type: '',
      icon: MenuIconhistoryleft,
      action: () => {
        if (isLogin) {
          router.push('/history');
        } else {
          dispatch(SettingActions.setLoginShow(true));
        }
      },
    },
    {
      key: 8,
      label: 'Referral Program',
      type: '',
      icon: MenuIconreferralleft,
      action: () => {
        if (isLogin) {
          dispatch(SettingActions.setLoading(true));
          router.push({
            pathname: '/referral',
          });
        } else {
          dispatch(SettingActions.setLoginShow(true));
        }
      },
    },
    {
      key: 6,
      label: 'VIP',
      type: '',
      icon: MenuIconvipleft,
      action: () => {
        if (isLogin) {
          dispatch(SettingActions.setLoading(true));
          router.push('/vip');
        } else {
          dispatch(SettingActions.setLoginShow(true));
        }
      },
    },
    {
      key: 10,
      label: 'FAQ',
      type: '',
      icon: MenuIconfaqleft,
      action: () => {
        dispatch(SettingActions.setLoading(true));
        router.push('/faq');
      },
    },
    {
      key: 11,
      label: 'Terms & Conditions',
      type: '',
      icon: MenuIcontermleft,
      action: () => {
        dispatch(SettingActions.setLoading(true));
        router.push('/terms-and-condition');
      },
    },
  ];

  const menuListMobile = [
    {
      key: 0,
      label: 'All Live',
      type: '',
      icon: MenuIconalllive,
      action: () => {
        if (Cookies.get('accessToken')) {
          accessToSport('/home/live');
        } else {
          setToPath('/home/live');
          accessToSport('/home/live', true);
          // router.push('/game_website?gameAccess=https://sportsbet.esportings.com/home/live?siteId=812');
        }
      },
    },
    {
      key: 1,
      label: 'Favorities',
      type: '',
      icon: MenuIconfavorities,
      action: () => {
        if (Cookies.get('accessToken')) {
          accessToSport('/favourite/matches');
        } else {
          setToPath('/favourite/matches');
          accessToSport('/favourite/matches', true);
        }
      },
    },
    {
      key: 2,
      label: 'Top Parlays',
      type: '',
      icon: MenuIcontopparlays,
      action: () => {
        if (Cookies.get('accessToken')) {
          accessToSport('/home/highlights');
        } else {
          setToPath('/home/highlights');
          accessToSport('/home/highlights', true);
        }
      },
    },
    {
      key: 3,
      label: 'Bonuses',
      type: '',
      icon: MenuIconpromotion,
      action: () => {
        if (Cookies.get('accessToken')) {
          accessToSport('/home/promotions');
        } else {
          setToPath('/home/promotions');
          accessToSport('/home/promotions', true);
        }
      },
    },
    {
      key: 4,
      label: 'Volleyball',
      type: '',
      icon: VolleyballIcon,
      action: () => {
        if (Cookies.get('accessToken')) {
          accessToSport('/sports/volleyball');
        } else {
          setToPath('/sports/volleyball');
          accessToSport('/sports/volleyball', true);
        }
      },
    },
    {
      key: 5,
      label: 'Football',
      type: '',
      icon: SoccerIcon,
      action: () => {
        if (Cookies.get('accessToken')) {
          accessToSport('/sports/football');
        } else {
          accessToSport('/sports/football', true);
        }
      },
    },
    {
      key: 7,
      label: 'Basketball',
      type: '',
      icon: BasketballIcon,
      action: () => {
        if (Cookies.get('accessToken')) {
          accessToSport('/sports/basketball');
        } else {
          setToPath('/sports/basketball');
          accessToSport('/sports/basketball', true);
        }
      },
    },
    {
      key: 8,
      label: 'Tennis',
      type: '',
      icon: TennisIcon,
      action: () => {
        if (Cookies.get('accessToken')) {
          accessToSport('/sports/tennis');
        } else {
          setToPath('/sports/tennis');
          accessToSport('/sports/tennis', true);
        }
      },
    },
    {
      key: 9,
      label: 'E-sports',
      type: '',
      icon: EsportIcon,
      action: () => {
        if (Cookies.get('accessToken')) {
          accessToSport('/sports/esports');
        } else {
          setToPath('/sports/esports');
          accessToSport('/sports/esports', true);
        }
      },
    },
  ];

  useEffect(() => {
    const onScroll = () => {
      const nav = document.getElementById('header-navbar');
      const leftSider = document.getElementById('left-sider');

      if (lastScrollY < elementWindow?.scrollTop) {
        if (elementWindow.scrollTop >= 58) {
          nav?.classList.add('header-navbar-hidden');
          leftSider?.classList.add('leftSider-padding');
        }
        // navblock.style.display = 'none';
      } else {
        nav?.classList.remove('header-navbar-hidden');
        leftSider?.classList.remove('leftSider-padding');
        // navblock.style.display = 'block';
      }
      lastScrollY = elementWindow.scrollTop;
    };
    // clean up code
    elementWindow?.removeEventListener('scroll', onScroll);
    elementWindow?.addEventListener('scroll', onScroll, { passive: true });
    return () => elementWindow?.removeEventListener('scroll', onScroll);
  }, [elementWindow]);

  useEffect(() => {
    function handleResize() {
      let orientation = 'landscape';
      if (window.innerHeight > window.innerWidth) {
        orientation = 'portrait';
      }
      dispatch(
        SettingActions.setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
          orientation,
        }),
      );
      dispatch(SettingActions.setIsMobile(window.innerWidth <= 768));
    }

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (Cookies.get('accessToken')) {
      axios
        .post(
          `${ENDPOINT}/v1/summ/gamelog/bonusCheck`,
          {
            userId: isId,
          },
          {
            headers: {
              Authorization: `Bearer ${Cookies.get('accessToken')}`,
            },
          },
        )
        .then((res) => {
          if (res?.data) {
            setBonusInfo(renderBonusProgress(res?.data));
          }
        });
    }
  }, [isId]);

  function renderBonusProgress(data) {
    const { bonusClaimInfo, baseTurnWinInfo } = data;
    if (bonusClaimInfo?.winoverAmount || baseTurnWinInfo?.winoverAmount) {
      const winoverAmount = (baseTurnWinInfo?.winoverAmount || 0)
        + (bonusClaimInfo?.winoverAmount || 0);
      return {
        a: (baseTurnWinInfo?.currWinoverAmount || 0).toFixed(3),
        b: (winoverAmount || 0).toFixed(3),
      };
    }
    if (bonusClaimInfo?.turnoverAmount || baseTurnWinInfo?.turnoverAmount) {
      const turnoverAmount = (baseTurnWinInfo?.turnoverAmount || 0)
        + (bonusClaimInfo?.turnoverAmount || 0);
      return {
        a: (baseTurnWinInfo?.currTurnoverAmount || 0).toFixed(3),
        b: (turnoverAmount || 0).toFixed(3),
      };
    }
    return {
      a: 0,
      b: 0,
    };
  }

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;

      for (let i = 0; i <= 3; i++) {
        if (height <= 0) {
          setTimeout(() => {
            if (ref?.current?.clientHeight) {
              setHeight(ref.current.clientHeight);
            }
          }, 500);
        } else {
          break;
        }
      }
    } else if (ref?.current?.clientHeight) {
      setHeight(ref.current.clientHeight);
    }
  });

  useEffect(() => {
    let result = props.banners?.filter((i) => i.isVisible);
    result = _.sortBy(result, ['sorting']);

    if (isMobile) {
      setValidBanners([{}].concat(result));
    } else {
      setValidBanners(result);
    }
  }, [props.banners, isMobile]);

  useEffect(() => {
    if (loginShow) {
      setShowModal(false);
    }
  }, [loginShow]);

  const toggleShowModal = () => {
    setShow(!show);
  };

  const toggleShowCustomLiveChatModal = () => {
    setShowCustomLiveChatModal(!showCustomLiveChatModal);
  };

  const toggleUserProfileModal = () => {
    setShowUserProfileModal(!showUserProfileModal);
  };

  useEffect(() => {
    if (!isLogin) {
      if (referrer) {
        const referrerObj = {
          expiryDate: new Date(
            new Date(new Date().setDate(new Date().getDate() + 30)),
          ),
          referrerCode: referrer,
        };
        // local storage
        if (typeof window !== 'undefined' && window.localStorage) {
          localStorage.setItem('referrer', JSON.stringify(referrerObj));
        }
        // cookies
        Cookies.set('referrer', JSON.stringify(referrerObj));
        dispatch(SettingActions.setRegisterShow(true));
      }
    }
  }, [referrer]);

  const toggleContactModal = () => {
    setContactShow(!contactShow);
  };

  const toggleDepositModal = () => {
    setShowDepositModal(!showDepositModal);
    if (!showDepositModal === false) {
      dispatch(SettingActions.setMobileMenuSelected(-1));
    }
  };

  const toggleWithdrawModal = () => {
    setShowWithdrawModal(!showWithdrawModal);
    if (!showWithdrawModal === false) {
      dispatch(SettingActions.setMobileMenuSelected(-1));
    }
  };

  useEffect(() => {
    clearTimeout(timer);
    const newTimer = setTimeout(async () => {
      verifyToken();
    }, 1000);

    setTimer(newTimer);
  }, [isLogin]);

  const getBal = () => getBalance()
    .then((res) => {
      dispatch(SettingActions.setAccountLoading(false));
      dispatch(SettingActions.setBalance(res?.data?.balance || '0.00'));
    })
    .catch((err) => {
      dispatch(SettingActions.setAccountLoading(false));
      console.log({ err });
    });

  const verifyToken = async () => {
    if (isLogin) {
      const result = await Verify({ accessToken: isLogin });
      if (result?.data === 'Authenticated') {
        setLogout(false);
        getBal();
      } else {
        // NotAuthenticated: You have been logged out, please login again
        if (result?.includes('NotAuthenticated')) {
          setIsRelogin(true);
        }
        setLogout(true);
      }
    }
  };

  useEffect(() => {
    if (isLoading) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isLoading]);

  useEffect(() => {
    if (logout) {
      Cookies.remove('accessToken');
      Cookies.remove('userId');
      Cookies.remove('username');
      Cookies.remove('fullName');
      if (isRelogin) {
        router.push('/');
        router.reload();
      }
    }
  }, [logout, isRelogin]);

  useEffect(() => {
    if (!isLogin) {
      const allowedRoutes = [
        '/',
        '/promotions',
        '/games',
        '/faq',
        '/login',
        '/register',
        '/contact-us',
        '/forgot-password',
        '/terms-and-condition',
        '/[category]',
        '/game_website',
      ];

      if (
        !allowedRoutes.some(
          (route) => router.route === route || router.route.includes(route),
        )
      ) {
        router.push('/');
        dispatch(SettingActions.setLoginShow(true));
      }
    }
  }, [isLogin]);

  useEffect(() => {
    if (!isLogin) {
      if (router.pathname === '/login') {
        dispatch(SettingActions.setLoginShow(true));
      } else if (router.pathname === '/register') {
        dispatch(SettingActions.setRegisterShow(true));
      }
    }

    if (router.pathname === '/contact-us') {
      setContactShow(true);
    }
  }, [router.pathname]);

  const getSportData = async () => {
    // const result = await getSportMatches();

    // if (result?.games?.length > 0) {
    //   const filterArrMobile = {};
    //   result?.games?.map((x) => {
    //     if (!filterArrMobile[x.sport_alias]) {
    //       filterArrMobile[x.sport_alias] = [];
    //     }
    //   });

    //   result?.games?.map((x) => {
    //     if (filterArrMobile[x.sport_alias]) {
    //       if (x.category_name.toLowerCase().includes('england')) {
    //         x.category_name = 'United Kingdom';
    //       } else if (x.category_name.toLowerCase().includes('usa')) {
    //         x.category_name = 'United States';
    //       }
    //       filterArrMobile[x.sport_alias].push(x);
    //     }
    //   });
    const defaultPopular = [
      {
        title: 'Soccer',
        content: [
          {
            category_name: 'United Kingdom',
            link: '/sports/soccer/england/premier-league',
            tournament_name: 'Premier League',
          },
          {
            category_name: 'International',
            link: '/sports/soccer/international/uefa-euro-qualification',
            tournament_name: 'European Championship Qualification',
          },
          {
            category_name: 'United Kingdom',
            link: '/sports/soccer/international/fa-cup',
            tournament_name: 'FA Cup',
          },
          {
            category_name: 'United Kingdom',
            link: '/sports/soccer/international/championship',
            tournament_name: 'Championship',
          },
          {
            category_name: 'United Kingdom',
            link: '/sports/soccer/international/league-one',
            tournament_name: 'League One',
          },
          {
            category_name: 'United Kingdom',
            link: '/sports/soccer/international/league-two',
            tournament_name: 'League Two',
          },
          {
            category_name: 'United Kingdom',
            link: '/sports/soccer/spain/national-league',
            tournament_name: 'National League',
          },
          {
            category_name: 'Spain',
            link: '/sports/soccer/spain/copa-del-rey',
            tournament_name: 'Copa del Rey',
          },
          {
            category_name: 'Spain',
            link: '/sports/soccer/spain/laliga-2',
            tournament_name: 'LaLiga 2',
          },
          {
            category_name: 'France',
            link: '/sports/soccer/germany/ligue-1',
            tournament_name: 'Ligue 1',
          },
        ],
      },
    ];
    setSportMatchesMobile(defaultPopular);
  };

  useEffect(() => {
    getSportData();
  }, []);

  return (
    <>
      <div
        style={{
          backgroundColor: '#EDEDED',
        }}
        className='hidden-scrollbar'
      >
        <div
          className={`relative h-screen overflow-hidden container ${router.pathname !== '/game_website' && 'flex'
          }`}
          style={{
            boxShadow: '0px 0 2px 1px rgb(0 0 0 / 0.1)',
          }}
        >
          {hideSidebar && (
            <div className='absolute top-12 left-0.5 z-[200] hidden md:block'>
              <div
                className='leading-none rounded-tl-lg rounded-bl-lg py-1.5 px-1.5 sidebar-menu-back-open hover:shadow cursor-pointer rotate-180'
                onClick={() => setHideSidebar(false)}
              >
                <Image src={Hamburgersvg} width={18} height={15} alt='' placeholder='empty' />
              </div>
            </div>
          )}

          <div
            className={`hidden h-screen pb-20 transition-all duration-200	ease-linear ${router.pathname !== '/game_website' && 'md:block'
            }`}
            style={{
              maxWidth: hideSidebar ? 2 : 260,
              width: '100%',
            }}
          >
            <div>
              <div
                className={`shadow relative bg-white h-14 flex items-center justify-between transition-all ${hideSidebar && 'opacity-0'
                }`}
              >
                <div className='ml-4 flex items-end'>
                  {/* <div className='leading-none cursor-pointer' onClick={() => setHideSidebar(true)}>
                    <Image   src={Hamburgersvg} alt='' />
                  </div> */}
                  <Link href='/'>
                    <div className='main-menu-logo cursor-pointer flex items-end pb-0.5'>
                      <Image
                        alt='Bleon77'
                        onClick={() => {
                          dispatch(SettingActions.setLoading(true));
                          router.push('/');
                          setTimeout(() => {
                            dispatch(SettingActions.setLoading(false));
                          }, 500);
                        }}
                        src={logoIcon}
                      // width={110}
                      // height={42}
                      />
                    </div>
                  </Link>
                  <div className='cursor-pointer menu-get-bonus ml-4'>
                    <Link href='/promotions'>
                      <div className='leading-none bonug-img'>
                        <Image src={GetBonus} alt='Get Bonus' placeholder='empty' />
                      </div>
                    </Link>
                  </div>
                </div>
                {/* <div className='flex items-center'> */}
                {/* <div className='leading-none mr-3 relative cursor-pointer'>
                    <Image   src={languageIcon} alt='' width={30} height={30} />
                    <div className='absolute -bottom-1.5 -right-1'>
                      <Image   src={SideMenuLanguageDown} alt='' width={18} height={18} />
                    </div>
                  </div> */}
                {/* <div
                    className='leading-none rounded-tl-xl rounded-bl-xl py-0.5 sidebar-menu-back cursor-pointer pl-0.5'
                    style={{
                      border: '2px solid',
                      borderColor: '#d5d5d7',
                      marginRight: -2,
                    }}
                    onClick={() => setHideSidebar(true)}
                  >
                    <Image   src={SideMenuBack} alt='' />
                  </div> */}
                {/* </div> */}
              </div>

              <div
                // id='left-sider'
                className={`hidden h-screen overflow-y-auto hidden-scrollbar pb-20 bg-white ${router.pathname !== '/game_website' && 'md:block'
                }`}
              >
                <div className='overflow-y-auto'>
                  <div>
                    <SideMenu
                      menuList={menuList2}
                      setMenuSelected={setMenuSelected}
                    />
                  </div>
                  {/* <div className='mt-4'>
                    {sportMatchesMobile.map((x, i) => (
                      <div key={x.title + i} className='mb-4'>
                        <h5 className='font-semibold text-textblack uppercase ml-4'>
                          Popular
                        </h5>
                        <div
                          className='mx-2 rounded-lg overflow-hidden'
                          style={{ background: '#F6F6F6' }}
                        >
                          {x.content.map((x1, i1) => (
                            <div
                              key={`${x1.id}${i1}`}
                              className='py-2 m-auto side-menu-2-country'
                              onClick={() => {
                                if (Cookies.get('accessToken')) {
                                  accessToSport(x1.link);
                                } else {
                                  setToPath(x1.link);
                                  accessToSport(x1.link, true);
                                }
                              }}
                            >
                              <div className='grid grid-cols-12'>
                                <div className='col-span-2 self-center'>
                                  <div>
                                    {x1.category_name === 'International' ? (
                                      <img
                                        className='inline-block mr-1.5'
                                        src='https://sportsbet.esportings.com/static/images/flags/world.svg'
                                        width={25}
                                        alt='International'
                                      />
                                    ) : (
                                      <i className='text-2xl mr-1.5'>
                                        {
                                          x1.category_name === 'International'
                                            ? <img className='inline-block mr-1.5' src='https://sportsbet.esportings.com/static/images/flags/world.svg' width={20} alt='International' />
                                            : (
                                              <i className='text-2xl mr-1.5'>
                                                {countiesCode.find((country) => country.name?.toLowerCase() === x1.category_name?.toLowerCase())?.flag}
                                              </i>
                                            )
                                        }
                                      </i>
                                    )}
                                  </div>
                                </div>
                                <div className='col-span-9'>
                                  <div>
                                    <p className='text-2xs text-textblack leading-none'>
                                      {x.title}
                                      {' - '}
                                      {x1.category_name}
                                    </p>
                                    <h4 className='text-xs font-medium opacity-100'>
                                      {x1.tournament_name}
                                    </h4>
                                  </div>
                                </div>
                                <div className='col-span-1 flex items-center justify-end'>
                                  <div
                                    className='leading-none mr-1'
                                    style={{ width: 7 }}
                                  >
                                    <Image src={MenuChevronLeft} alt='' placeholder='empty' />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div> */}

                  <div>
                    <SideMenu
                      menuList={menuList3}
                      setMenuSelected={setMenuSelected}
                    />
                  </div>

                  {/* <a target='_blank' href='https://betleon.s3.ap-southeast-1.amazonaws.com/apk/BLEON77.apk' rel='noopener noreferrer'> */}
                  <div className='rounded-xl overflow-hidden mt-6'>
                    <Image src={DownloadApk} alt='APP QR Code' placeholder='empty' />
                  </div>
                  {/* </a> */}
                </div>
              </div>
            </div>
          </div>

          <div
            id='page-container'
            className='col-span-10 h-screen overflow-y-auto hidden-scrollbar relative w-full'
          >
            {/* <div id='header-block' style={{ height: 62 }} /> */}
            <div id='header-navbar' className='z-50 shadow'>
              <div className='flex items-center h-14 justify-between bg-white'>
                <MenuSection
                  setToPath={setToPath}
                  accessToSport={accessToSport}
                />

                <div className='flex items-center md:hidden'>
                  <Link href='/'>
                    <div
                      className='main-menu-logo ml-2 md:ml-4 cursor-pointer'
                      onClick={() => {
                        dispatch(SettingActions.setLoading(true));
                        router.push('/');
                        setTimeout(() => {
                          dispatch(SettingActions.setLoading(false));
                        }, 500);
                      }}
                    >
                      <Image

                        alt='Live Casino India'
                        placeholder='empty'
                        onClick={() => {
                          dispatch(SettingActions.setLoading(true));
                          router.push('/');
                          setTimeout(() => {
                            dispatch(SettingActions.setLoading(false));
                          }, 500);
                        }}
                        src={logoIcon}
                        width={1478}
                        height={521}
                      />
                    </div>
                  </Link>
                  <div className='cursor-pointer menu-get-bonus ml-4'>
                    <Link href='/promotions'>
                      <div className='leading-none bonug-img'>
                        <Image src={GetBonus} alt='Get Bonus' placeholder='empty' />
                      </div>
                    </Link>
                  </div>
                </div>

                <div className='w-full md:w-auto mr-1 md:mr-2'>
                  {isLogin ? (
                    <div className='flex justify-end items-center flex-wrap'>
                      <Link href='/deposit'>
                        <div
                          className='mx-1 rounded-full p-1 flex items-center cursor-pointer'
                          style={{
                            background: '#EDEDED',
                          }}
                        >
                          <div className='px-2'>
                            <div className='font-semibold text-xs tracking-tight opacity-60'>
                              {depositCurrency}
                              {parseFloat(balance).toLocaleString('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }) || '0.00'}
                            </div>
                          </div>
                          <div
                            className='rounded-full flex justify-center align-center items-center'
                            style={{
                              backgroundColor: '#22A35F',
                              width: 22,
                              height: 22,
                            }}
                          >
                            <PlusOutlined
                              style={{
                                color: 'white',
                                fontSize: 14,
                              }}
                            />
                          </div>
                        </div>
                      </Link>
                      <Link href='/profile'>
                        <div
                          className='cursor-pointer flex rounded-full items-center justify-center inner-shadow'
                          // onClick={() => {
                          //   toggleUserProfileModal();
                          // }}
                          style={{
                            backgroundColor: '#22A35F',
                            width: 30,
                            height: 30,
                          }}
                        >
                          <UserOutlined
                            style={{
                              color: 'white',
                              fontSize: 14,
                            }}
                          />
                        </div>
                      </Link>
                    </div>
                  ) : (
                    <LoginPage />
                  )}
                </div>
                {/* </div> */}
              </div>
            </div>

            <div className=''>
              {/* HelpCenter */}
              <LiveChat
                setShowCustomLiveChatModal={setShowCustomLiveChatModal}
                showCustomLiveChatModal={showCustomLiveChatModal}
              />

              <div className='relative mx-auto'>
                {/* {router.pathname !== '/' && router.pathname !== '/game_website' && (
                  <div className='mb-auto md:hidden'>
                    <div>
                      <MenuSectionMobile />
                    </div>
                  </div>
                )} */}

                <div
                  className={`mb-auto h-full ${router.pathname !== '/game_website' && ''
                  }`}
                >
                  <div className='h-full'>
                    <div className='games-container'>
                      <div className='relative'>{props.children}</div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end header */}
            </div>
            <Footer setContactShow={setContactShow} />
          </div>
        </div>
      </div>

      {/* LIVE CHAT */}
      {showCustomLiveChatModal ? (
        <div
          className={`right-1 fixed ${windowSize?.orientation === 'landscape' && isMobile
            ? 'bottom-0 livechat-custom-landscape'
            : 'md:bottom-20 bottom-10 livechat-custom'
          }`}
          style={{ zIndex: 99 }}
        >
          <div
            className='px-4 flex justify-between items-center'
            style={{
              background: '#fff',
              // backgroundImage: 'linear-gradient(#33424C, #1A242E)',
              height: '8%',
            }}
          >
            <span className='-mt-1 inline-block w-24 ml-0.5'>
              <Image alt='Live Casino India' src={logoIcon} placeholder='empty' />
            </span>
            <button onClick={() => setShowCustomLiveChatModal(false)}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='30'
                height='30'
                fill='grey'
                className='bi bi-dash'
                viewBox='0 0 16 16'
              >
                <path d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z' />
              </svg>
            </button>
          </div>
          <div style={{ height: '80%' }}>
            <iframe
              scrolling='no'
              style={{ overflow: 'hidden' }}
              id='bleon-livechat'
              className='w-full h-full'
              src={LIVECHATURL}
            />
          </div>
        </div>
      ) : null}

      {/* modals */}
      <div className=''>
        {show && (
          <MobileMenuModal
            show={show}
            setShow={setShow}
            toggleModal={toggleShowModal}
            menuList={menuList2}
            menuList3={menuList3}
            menuList4={menulist4}
            menuList5={menulist5}
            menuList6={menulist6}
            menuListMobile={menuListMobile}
            menuSelected={menuSelected}
            setMenuSelected={setMenuSelected}
            bonusInfo={bonusInfo}
            profileShowModal={showUserProfileModal}
            profileToggleModal={toggleUserProfileModal}
          />
        )}

        <ContactUsModal show={contactShow} toggleModal={toggleContactModal} />

        {router.pathname !== '/game_website' && (
          <NavbarBottom
            show={showModal}
            setShowModal={setShowModal}
            toggleShowCustomLiveChatModal={toggleShowCustomLiveChatModal}
            setShow={setShow}
          />
        )}

        {isLoading && <LoadingModal />}

        {isAlert.length > 0 && (
          <AlertModal message={isAlert[0]?.msg} action={isAlert[1]} />
        )}

        <LoginModal />
        <RegisterModal />
      </div>
    </>
  );
}

export default Layout;
